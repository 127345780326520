import { Icon } from 'fitify-ui'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { InputProps } from '@/components/forms/Input/Input.Types'

import {
  StyledIconWrapper,
  StyledInput,
  StyledInputWrapper,
  StyledSuffix,
} from './Input.Styled'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      hasError = false,
      isDisabled = false,
      icon,
      onWrapperClick,
      suffix,
      value,
      variant = 'underline',
      variantSize = 'md',
      ...htmlProps
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => inputRef.current!, [])

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isDisabled) {
        inputRef.current?.focus()
        onWrapperClick?.(event)
      }
    }

    return (
      <StyledInputWrapper
        className={className}
        $hasError={hasError}
        onClick={handleClick}
        $variant={variant}
        $variantSize={variantSize}
      >
        {icon ? (
          <StyledIconWrapper $variantSize={variantSize}>
            <Icon name={icon} />
          </StyledIconWrapper>
        ) : null}
        <StyledInput
          ref={inputRef}
          autoComplete="off"
          disabled={isDisabled}
          $hasError={hasError}
          onWheelCapture={() => {
            inputRef.current?.blur()
          }}
          value={value ?? ''}
          $variant={variant}
          $variantSize={variantSize}
          {...htmlProps}
        />
        {suffix ? <StyledSuffix>{suffix}</StyledSuffix> : null}
      </StyledInputWrapper>
    )
  }
)

Input.displayName = 'Input'
